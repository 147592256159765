import React from 'react';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { Box, Card, CardActionArea, Container, Grid, LinearProgress, Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import VideocamIcon from '@material-ui/icons/Videocam';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { defineMessages, useIntl } from 'react-intl';
import TimelineIcon from '@material-ui/icons/Timeline';
import { ReactComponent as EmptyIcon } from 'icons/undraw_empty_street.svg';
import { useFetchActivities } from 'modules/activity/hooks/useFetchActivities';
import { orderBy } from 'lodash';
import moment from 'moment';
import { ActivityType, ActivityWithCount } from 'modules/project/types';
import { Link } from 'react-router-dom';

const m = defineMessages({
  title: {
    id: 'ActivityStream.Title',
    defaultMessage: "Flux d'activité",
  },
  noActivity: {
    id: 'ActivityStream.NoActivity',
    defaultMessage: "Aucune activité"
  },
  comeBackLater: {
    id: 'ActivityStream.ComeBackLater',
    defaultMessage: "Revenez plus tard"
  },
  photoUploadActivity: {
    id: 'ActivityStream.PhotoUploadActivity',
    defaultMessage: "{username} a téléversé {count, plural, one {# photo} other {# photos}}"
  },
  videoUploadActivity: {
    id: 'ActivityStream.VideoUploadActivity',
    defaultMessage: "{username} a téléversé {count, plural, one {# vidéo} other {# vidéos}}"
  },
  photoDeleteActivity: {
    id: 'ActivityStream.PhotoDeleteActivity',
    defaultMessage: "{username} a supprimé {count, plural, one {# photo} other {# photos}}"
  },
  videoDeleteActivity: {
    id: 'ActivityStream.VideoDeleteActivity',
    defaultMessage: "{username} a supprimé {count, plural, one {# vidéo} other {# vidéos}}"
  },
  mediaDownloadActivity: {
    id: 'ActivityStream.MediaDownloadActivity',
    defaultMessage: "{username} a téléchargé {count, plural, one {# image/vidéo} other {# images/vidéos}}"
  }
});


export const ActivityStream: React.FC = () => {  
  const { formatMessage } = useIntl(); 
  const {activities, isFetched} = useFetchActivities(3);

  const activitiesOrdered = orderBy(activities, (i => i.date), ['desc']);  
  const groupActivities = activitiesOrdered.reduce<ActivityWithCount[]>((previousValue, currentValue, currentIndex) => {
    if(currentIndex === 0){
      previousValue.push({...currentValue, count: currentValue.mediaCount});
    }
    else {
      let previousActity = previousValue[previousValue.length -1];
      if(previousActity.projectId === currentValue.projectId 
        && previousActity.applicationUser.name === currentValue.applicationUser.name
        && previousActity.activityType === currentValue.activityType) {
          previousActity.count += currentValue.mediaCount;
      }
      else{
        previousValue.push({...currentValue, count: currentValue.mediaCount});
      }
    }
    return previousValue;
  }, [])  

  return (
    <>
      <Container maxWidth="lg" className="tw-flex tw-flex-col tw-mt-4">
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid container item direction="row" alignItems="center" xs={6}>
            <TimelineIcon />
            <Typography component="h2" variant="h6" color="primary" className="tw-ml-2">{formatMessage(m.title)}</Typography>
          </Grid>
        </Grid>
      </Container>
      {isFetched ? (
        <>
          {activities.length > 0 ? (
            <Container maxWidth="lg" className="tw-flex tw-flex-col">
              <Timeline align="alternate">
                {groupActivities.map((activity, index) => {
                  const projectFullName = `${activity.project.contractNumber === null ? "" : activity.project.contractNumber + " - "} ${activity.project.clientFirstName} ${activity.project.clientLastName}`.trimStart();
                  return (               
                    <TimelineItem key={index}>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary" className="tw-mt-3">
                          {moment.utc(activity.date).local().calendar()}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="primary" variant="outlined">
                          {activity.activityType === ActivityType.PhotoUpload && <CameraIcon />}
                          {activity.activityType === ActivityType.VideoUpload && <VideocamIcon />}
                          {(activity.activityType === ActivityType.PhotoDelete ||
                            activity.activityType === ActivityType.VideoDelete) && <DeleteIcon />}
                          {activity.activityType === ActivityType.MediaDownload && <CloudDownloadIcon />}                          
                        </TimelineDot>
                      <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Card>
                          <CardActionArea component={Link} to={`/projects/${activity.projectId}`} className="tw-p-2">
                            <Typography variant="body1" component="h3" className="tw-font-semibold">{projectFullName}</Typography>
                            {activity.activityType === ActivityType.PhotoUpload && (
                              <Typography variant="body2">
                                {formatMessage(m.photoUploadActivity, {username: activity.applicationUser.name, count: activity.count})}
                              </Typography>
                            )}

                            {activity.activityType === ActivityType.VideoUpload && (
                              <Typography variant="body2">
                                {formatMessage(m.videoUploadActivity, {username: activity.applicationUser.name, count: activity.count})}
                              </Typography>
                            )}

                            {activity.activityType === ActivityType.PhotoDelete && (
                              <Typography variant="body2">
                                {formatMessage(m.photoDeleteActivity, {username: activity.applicationUser.name, count: activity.count})}
                              </Typography>
                            )}
                            
                            {activity.activityType === ActivityType.VideoDelete && (
                              <Typography variant="body2">
                                {formatMessage(m.videoDeleteActivity, {username: activity.applicationUser.name, count: activity.count})}
                              </Typography>
                            )}  

                            {activity.activityType === ActivityType.MediaDownload && (
                              <Typography variant="body2">
                                {formatMessage(m.mediaDownloadActivity, {username: activity.applicationUser.name, count: activity.count})}
                              </Typography>
                            )}                            
                          </CardActionArea>
                        </Card>
                      </TimelineContent>
                    </TimelineItem>  
                  )
                })}
              </Timeline>
            </Container>
          ) : (
            <Box className="tw-flex tw-flex-grow">
              <Grid container direction="column" justify="center" alignItems="center">
                <Typography component="h2" variant="h6">{formatMessage(m.noActivity)}</Typography>
                <EmptyIcon className="tw-w-2/5 tw-my-2 tw-max-w-lg" />
                <Typography>{formatMessage(m.comeBackLater)}</Typography>
              </Grid>
            </Box>
          )}
        </>
      ): (
        <LinearProgress />
      )}
      
    </>
  );
};
