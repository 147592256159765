import React from 'react';
import { Spinner } from "components/Spinner/Spinner";
import { Box, Container, Grid } from '@material-ui/core';

export const SplashScreen: React.FC = () => {
  return (
    <Grid className="tw-flex tw-items-center tw-h-screen tw-bg-red">
      <Container maxWidth="sm" className="tw-flex tw-flex-col">
        <Grid container direction="column" justify="center" alignItems="center">
          <Box className="tw-w-1/2">
            <Spinner/>
          </Box>  
        </Grid>
      </Container>
    </Grid>
  );
};
