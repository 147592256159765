import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { DownloadOptionsMenu } from "../download-options-menu";
import { MediaThumbnail } from "modules/project/types";
export interface ProjectsMediasProps {
    title: string;
    children?: React.ReactNode;
    projectId: string;
    medias: MediaThumbnail[];
}

export const ProjectMedias: React.FC<ProjectsMediasProps> = (props: ProjectsMediasProps) => {

    return (
        <Paper className="tw-p-2 tw-my-2">
            <Grid container justify="space-between" alignItems="center">
                <Typography variant="body1" component="h3" className="tw-font-semibold tw-pl-2 tw-capitalize">{props.title}</Typography>    
                <DownloadOptionsMenu projectId={props.projectId} medias={props.medias} isFullProject={false}/>          
            </Grid>
            <Grid container>
                {props.children}
            </Grid>
        </Paper>
    );
}