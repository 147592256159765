import { Reducer } from 'react';
import * as actionsTypes from "./actionTypes";
import { ProjectActionTypes, ProjectQueueMediaStatus, ProjectState } from './types';

const initialState = {
  uploadQueue: []
};

export const projectReducer: Reducer<ProjectState, ProjectActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionsTypes.QUEUE_MEDIA: 
      return {
        ...state,
        uploadQueue: [...state.uploadQueue, {
          projectId: action.payload.projectId,
          fileName: action.payload.fileName,
          previewUrl : "/images/square-image.png",
          status: ProjectQueueMediaStatus.uploading,
          media: undefined
        }]
      }
    case actionsTypes.MEDIA_UPLOADED:
      const index = state.uploadQueue.findIndex(m => m.projectId === action.payload.projectId && m.fileName === action.payload.fileName);
      return { 
        ...state,
        uploadQueue: [
        ...state.uploadQueue.slice(0, index),
        {
           ...state.uploadQueue[index],
           status: ProjectQueueMediaStatus.uploaded,
           media: action.payload.media
        },
        ...state.uploadQueue.slice(index + 1),
     ]}
    case actionsTypes.MEDIA_UPLOAD_FAILED:
      const failedIndex = state.uploadQueue.findIndex(m => m.projectId === action.payload.projectId && m.fileName === action.payload.fileName);
      return { 
        ...state,
        uploadQueue: [
        ...state.uploadQueue.slice(0, failedIndex),
        {
           ...state.uploadQueue[failedIndex],
           status: ProjectQueueMediaStatus.error
        },
        ...state.uploadQueue.slice(failedIndex + 1),
      ]}
     case actionsTypes.CLEAN_MEDIA_QUEUE:
      return { 
        ...state,
        uploadQueue: state.uploadQueue.filter(x => x.status === ProjectQueueMediaStatus.uploading)
      }
    default:
      return state;
  }
};
