export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || "",
        authority: "https://login.microsoftonline.com/organizations",
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false 
    },
    system: {       
        allowRedirectInIframe: true,
    }
}

export const apiConfig = {
    resourceScopes: [`${process.env.REACT_APP_CLIENT_ID}/.default`]
}

export const loginRequest = {
    scopes: ["offline_access", ...apiConfig.resourceScopes],
    redirectStartPage: '/',
    prompt: 'select_account'
}

export const silentRequest = {
    scopes: [...apiConfig.resourceScopes],
    forceRefresh: false
}