import { requests } from 'api';
import { User, Settings } from '.';

const apiBaseUrl = '/api/users';

export const AccountApi = {
  fetchCurrentUser: () : Promise<User> => requests.get(`${apiBaseUrl}/current`),
  fetchCurrentUserSettings: () : Promise<Settings> => requests.get(`${apiBaseUrl}/settings`),
  updateUserSettings: (setting: Settings) : Promise<Settings> => requests.put(`${apiBaseUrl}/settings`, setting),
}
