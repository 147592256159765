import React, { useEffect } from 'react';
import { ProjectCard } from '../project-card'
import { ProjectWithDistance } from '../../types';
import { useCurrentLocation } from 'modules/project/hooks/useCurrentLocation';
import { getDistance } from 'geolib';
import sortBy from "lodash/sortBy";
import take from "lodash/take";
import { defineMessages, useIntl } from 'react-intl';
import { useFetchProjectsNear } from 'modules/project/hooks/useFetchNearestProject';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { Box, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';

const m = defineMessages({
  title: {
    id: 'ProjectsNear.Title',
    defaultMessage: 'À proximité',
  },
  geolocationError: {
    id: 'ProjectsNear.GeolocationError',
    defaultMessage: "Une erreur s'est produite lors de la localisation de votre position ({errorCode})"
  },
  noProjects: {
    id: 'ProjectsNear.NoProject',
    defaultMessage: "Aucun projet trouvé à proximité"
  },
  refreshLocation: {
    id: 'ProjectsNear.RefreshLocation',
    defaultMessage: "Actualiser l'emplacement"
  }
});

export const ProjectsNear: React.FC = () => {  
  const { formatMessage } = useIntl();

  const { location, error: locationError, refreshMap } = useCurrentLocation();
  const { projects, isFetched: isProjectsFetched, setLocation } = useFetchProjectsNear();

  const NUMBER_OF_PROJECTS = 10;

  const projectsWithDistance = projects.map(project => {
    const distance = getDistance(
      {
        latitude: location?.coords.latitude || 0,
        longitude: location?.coords.longitude || 0
      },
      {
        latitude: project.clientAddressLatitude || 0,
        longitude: project.clientAddressLongitude || 0
      }
    );

    return { ...project, distance: distance } as ProjectWithDistance;
  });
  
  useEffect(() => {
    setLocation(location)
  }, [setLocation, location])
  

  const handleRefreshLocationClick = () => {
    refreshMap();
  }

  const sortedProjects = sortBy(projectsWithDistance, (p => p.distance));

  return (
    <Box>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid container item direction="row" alignItems="center" xs={6}>
          <MyLocationIcon />
          <Typography component="h2" variant="h6" color="primary" className="tw-ml-2">{formatMessage(m.title)}</Typography>
        </Grid>
        <IconButton aria-label={formatMessage(m.refreshLocation)} onClick={handleRefreshLocationClick}>
          <RefreshIcon />
        </IconButton>
      </Grid>
      {locationError ? (
        <Alert severity="error">{formatMessage(m.geolocationError, {errorCode: locationError.code})}</Alert>
      ) : (
        <>
          {isProjectsFetched ? (
            <>
              {sortedProjects !== null && sortedProjects.length > 0 ? (
                <>
                  {take(sortedProjects, NUMBER_OF_PROJECTS).map((project, index) => {
                    return ( 
                      <ProjectCard key={index} project={project}/>
                    )
                  })}
                </>
              ) : (
                <Alert severity="info">{formatMessage(m.noProjects)}</Alert>
              )}          
            </>
          ) : (
            <LinearProgress />
          )}
        </>
      )}
    </Box>
  );
};

