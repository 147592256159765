import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { silentRequest } from 'authConfig';
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { msalInstance } from 'index';
import { ApiError } from "./ApiError";

export const defaultApi = axios.create({
  responseType: 'json',
});

export const authorizationConfig = async(config?: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!config) {
    config = axios.defaults;
  }

  if (activeAccount || accounts.length > 0) {
    const request = {
      ...silentRequest,
      account: activeAccount || accounts[0]
    };
    
    try
    {
      const authResult = await msalInstance.acquireTokenSilent(request)
      config.headers["Authorization"] = `Bearer ${authResult.accessToken}`;
      return config;
    }
    catch(error) {
      if (error instanceof InteractionRequiredAuthError) {
        const redirectRequest = {
          scopes: request.scopes,
          loginHint: request.account.username
        };
        msalInstance.acquireTokenRedirect(redirectRequest);
      }
    }
  }
  return config;
}

defaultApi.interceptors.request.use(async config => {
  return await authorizationConfig(config);
})

defaultApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if(axios.isCancel(error)){
      return Promise.reject(error);
    }
    const { status, data } = error.response;
    return Promise.reject(
      new ApiError(
        status,
        data ? data.userMessage : error.message,
        data?.debugMessage,
        data?.debugDetails
      )
    );
  }
);

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
  get: (url: string, cancellationToken?: CancelTokenSource) => defaultApi.get(url, {cancelToken: cancellationToken?.token}).then(responseBody),
  post: (url: string, body: unknown) => defaultApi.post(url, body).then(responseBody),
  put: (url: string, body: unknown) => defaultApi.put(url, body).then(responseBody),
  patch: (url: string, body: unknown) => defaultApi.patch(url, body).then(responseBody),
  del: (url: string) => defaultApi.delete(url).then(responseBody),
};