import { defaultErrorHandler } from 'api';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ProjectsApi } from '../api';
import { Project } from '../types';

interface Output {
    projects: Project[];
    isFetched: boolean;
    setLocation: (position: GeolocationPosition | undefined) => void;
}
  
export const useFetchProjectsNear = () : Output => {

    const [position, setLocation] = useState<GeolocationPosition | undefined>(undefined);
    const [projects, setProjects] = useState<Project[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        
        if(position !== undefined)
        {
            setIsFetched(false);
            setProjects([]);
            ProjectsApi.getAllNear(position.coords.latitude, position.coords.longitude, source)
            .then((fetchedProjects) => {
                setProjects(fetchedProjects);
                setIsFetched(true);
            })
            .catch((error) => {
                setProjects([]);
                setIsFetched(false);
                if(!axios.isCancel(error)){
                    defaultErrorHandler(error);
                }
            });
        }

        return() => {
            source.cancel();
        };
        
    }, [position]);

    return { projects, isFetched, setLocation };
}