import { defaultErrorHandler } from 'api';
import axios from 'axios';
import { ProjectVideo } from 'modules/project/types';
import { useEffect, useState } from 'react';
import { VideosApi } from '../api';

interface Output {
    projectVideos: ProjectVideo[];
    isFetched: boolean;
}
  
export const useFetchProjectVideos = (projectId: string) : Output => {

    const [projectVideos, setProjectVideos] = useState<ProjectVideo[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        
        VideosApi.getVideos(projectId, source)
        .then((fetchedProjectVideos) => {
            setProjectVideos(fetchedProjectVideos);
            setIsFetched(true);
        })
        .catch((error) => {
            setProjectVideos([]);
            setIsFetched(false);
            if(!axios.isCancel(error)){
                defaultErrorHandler(error);
            }
        });
        
        return() => {
            source.cancel();
        };
    }, [projectId]);

    return { projectVideos, isFetched };
}