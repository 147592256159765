import React, { useEffect, useRef } from 'react';
import { msalInstance } from 'index';
import { silentRequest } from 'authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export interface SecureVideoProps {
    src: string;
}

export const SecureVideo: React.FC<SecureVideoProps> = (props:SecureVideoProps) => {

    const sourceEl = useRef<HTMLSourceElement>(null);
    const videoEl = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        //see https://stackoverflow.com/a/67628257
        async function fetchVideoStream() {
            const token = await getToken();
            const mediaResponse = await fetch(props.src, {
                headers: {
                    Authorization: `${token}`
                }
            });

            const reader = mediaResponse.body?.getReader();

            const stream = new ReadableStream({
                start(controller) {
                  return pump();
                  function pump():any {
                    return reader?.read().then(({ done, value }) => {
                      if (done) {
                        controller.close();
                        return;
                      }
                      controller.enqueue(value);
                      return pump();
                    });
                  }
                }
            });

            const blob = await new Response(stream).blob();

            if (blob && sourceEl.current && videoEl.current) {
              sourceEl.current.src = URL.createObjectURL(blob);
              videoEl.current.load();
            }
        }
        fetchVideoStream();
    }, [props.src]);

    const getToken = async () =>
    {
        const activeAccount = msalInstance.getActiveAccount();
        const accounts = msalInstance.getAllAccounts();
      
        if (activeAccount || accounts.length > 0) {
          const request = {
            ...silentRequest,
            account: activeAccount || accounts[0]
          };
          
          try
          {
            const authResult = await msalInstance.acquireTokenSilent(request)
            return `Bearer ${authResult.accessToken}`;
          }
          catch(error) {
            if (error instanceof InteractionRequiredAuthError) {
              const redirectRequest = {
                scopes: request.scopes,
                loginHint: request.account.username
              };
              msalInstance.acquireTokenRedirect(redirectRequest);
            }
          }
        }
        return '';
    }

    return (
        <video ref={videoEl} controls className='tw-w-full tw-max-h-full'>
            <source ref={sourceEl} type='video/mp4' />
        </video>
    );
};
