import { AppBar, Box, Divider, IconButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { RootState } from 'store';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const m = defineMessages({
  logout: {
    id: 'TopBar.Logout',
    defaultMessage: "Se déconnecter",
  },
});

export const TopBar: React.FC = () => {  
  const { formatMessage } = useIntl(); 
  const userName = useSelector<RootState, string>(state => state.account.user?.name || "")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { instance } = useMsal();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    instance.logoutRedirect({postLogoutRedirectUri: "/"});
  }

  return (
    <AppBar position="sticky" color="secondary">
        <Toolbar>
            <Box className="tw-flex-grow">
              <Link to={"/"} className="tw-inline">
                <Typography variant="h6" >Cam Bisson</Typography>
              </Link>
            </Box>
            <IconButton
            aria-label="current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            >
            <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                >
                  <ListSubheader color="primary" style={{lineHeight:'2rem'}}>{userName}</ListSubheader>
                  <ListSubheader style={{lineHeight:'1rem', marginBottom:'0.25rem', fontWeight:'normal'}}>version:{process.env.REACT_APP_VERSION}</ListSubheader>
                  <Divider />
                  <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={formatMessage(m.logout)} />
                  </MenuItem>
                </Menu>
        </Toolbar>
    </AppBar>  
  );
};