import React from 'react';
import { NavLink } from 'react-router-dom';
import { SecureImage } from 'components/SecureImage';
import LazyLoad from 'react-lazyload';

export interface ProjectMediaThumbnailProps {
    mediaUrl?: string,
    previewUrl: string,
    projectId: string,
    className?: string,
}

export const ProjectMediaThumbnail: React.FC<ProjectMediaThumbnailProps> = (props : ProjectMediaThumbnailProps) => {

    const mediaName = props.mediaUrl ? props.mediaUrl.substring(props.mediaUrl.lastIndexOf('/') + 1) : "";

    return(
        <NavLink to={{ pathname: `/projects/${props.projectId}/${mediaName}`}}>
            <div className={props.className}>
                <LazyLoad height={116} scrollContainer={"#page-container"}>
                    <SecureImage src={props.previewUrl} imageStyle={{height: 116, padding: 2}} cover style={{width: 116, height: 116}} />
                </LazyLoad>
            </div>            
        </NavLink>
    )
}
