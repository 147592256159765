import { AppBar, Box, Button, Container, Grid, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from "react-router";

const m = defineMessages({
  home: {
    id: 'Errors.ErrorPage.Home',
    defaultMessage: 'Accueil',
  },
  back: {
    id: 'Errors.ErrorPage.Back',
    defaultMessage: 'Retour',
  },
  login: {
    id: 'Errors.ErrorPage.Login',
    defaultMessage: "S'identifier",
  }
});

interface Props {
  title: string;
  message: string;
  errorCode?: number;
  svgImage: JSX.Element;
}

export const ErrorPage: React.FC<Props> = ({ title, message, errorCode, svgImage }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleLoginButton = () => history.push('/login');
  const handleHomeButton = () => history.push('/');
  const handleBackButton = () => history.goBack();

  return (
    <Box className="tw-flex tw-flex-col tw-min-h-screen">
      <AppBar position="sticky" color="secondary">
          <Toolbar>
            <Typography variant="h6" className="tw-flex-grow tw-ml-4">Cam Bisson</Typography>
          </Toolbar>
      </AppBar>
      <Box className="tw-flex tw-flex-grow tw-py-10">
        <Container maxWidth="sm" className="tw-flex tw-flex-col tw-justify-center">
          <Grid container direction="column" justify="center" alignItems="center">
            {svgImage}
            <Typography variant="h4" align="center" className="tw-py-4">{title}</Typography>
            <Typography paragraph align="center">{message}</Typography>
            <Grid container justify="space-around" alignItems="center" className="tw-pt-4">
              <Button variant="outlined" color="primary" onClick={handleBackButton}>{formatMessage(m.back)}</Button>
              {errorCode === 401 || errorCode === 403 ? (
                <Button variant="contained" color="primary" onClick={handleLoginButton}>{formatMessage(m.login)}</Button>
              ):(
                <Button variant="contained" color="primary" onClick={handleHomeButton}>{formatMessage(m.home)}</Button>
              )}        
            </Grid>
          </Grid>
        </Container>
      </Box>
  </Box>
  );
};