import * as actionsTypes from "./actionTypes";
import { MediaUploadedItem, ProjectActionTypes, ProjectMedia } from "./types";

export function queueMedia(mediaUpload: ProjectMedia): ProjectActionTypes {
    return {
        type: actionsTypes.QUEUE_MEDIA,
        payload: mediaUpload
    };
  }

export function uploadMedia(mediaUpload: ProjectMedia): ProjectActionTypes {
  return {
      type: actionsTypes.UPLOAD_MEDIA,
      payload: mediaUpload
  };
}

export function mediaUploaded(mediaUploaded: MediaUploadedItem): ProjectActionTypes {
    return {
        type: actionsTypes.MEDIA_UPLOADED,
        payload: mediaUploaded
    };
}

export function mediaUploadFailed(mediaUpload: ProjectMedia): ProjectActionTypes {
    return {
        type: actionsTypes.MEDIA_UPLOAD_FAILED,
        payload: mediaUpload
    };
}

export function cleanMediaQueue(projectId: string): ProjectActionTypes {
    return {
        type: actionsTypes.CLEAN_MEDIA_QUEUE,
        payload: projectId
    };
}
