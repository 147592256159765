import { defaultErrorHandler } from 'api';
import axios from 'axios';
import { Activity } from 'modules/project/types';
import { useEffect, useState } from 'react';
import { ActivitiesApi } from '../api';

interface Output {
    activities: Activity[];
    isFetched: boolean;
}
  
export const useFetchActivities = (days: number | undefined) : Output => {

    const [activities, setActivities] = useState<Activity[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();

        ActivitiesApi.getRecent(days, source)
        .then((fetchedActivities) => {
            setActivities(fetchedActivities);
            setIsFetched(true);
        })
        .catch((error) => {
            setActivities([]);
            setIsFetched(false);
            if(!axios.isCancel(error)){
                defaultErrorHandler(error);
            }
        });

        return() => {
            source.cancel();
        };

    }, [days]);

    return { activities, isFetched };
}