import { call, put, takeEvery } from 'redux-saga/effects';
import { AccountApi } from './api';
import * as actionsTypes from './actionTypes';
import { fetchCurrentUserFailure, fetchCurrentUserSuccess, updateUsersettingsFailure, updateUsersettingsSuccess } from './actions';
import { User, Settings } from '.';
import { SagaIterator } from 'redux-saga';

function* fetchCurrentUser() {
  try {
    const currentUser : User = yield call(AccountApi.fetchCurrentUser);
    const currentUserSettings : Settings = yield call(AccountApi.fetchCurrentUserSettings);
    yield put(fetchCurrentUserSuccess(currentUser, currentUserSettings));
  } catch (error) {
    yield put(fetchCurrentUserFailure());
  }
}

function* updateUserSettings(action: any) {
  try {
    const settings : Settings = yield call(AccountApi.updateUserSettings, action.payload as Settings);
    yield put(updateUsersettingsSuccess(settings));
  } catch (error) {
    yield put(updateUsersettingsFailure());
  }
}


export default function* AccountSagas() : SagaIterator {
  yield takeEvery(actionsTypes.FETCH_CURRENT_USER, fetchCurrentUser);
  yield takeEvery(actionsTypes.UPDATE_USER_SETTINGS, updateUserSettings);
}
