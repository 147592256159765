import * as actionsTypes from './actionTypes';
import { AccountActionTypes, User, Settings } from '.';

export function fetchCurrentUser(): AccountActionTypes {
  return {
    type: actionsTypes.FETCH_CURRENT_USER,
  };
}

export function fetchCurrentUserSuccess(user: User, settings: Settings): AccountActionTypes {
  return {
    type: actionsTypes.FETCH_CURRENT_USER_SUCCESS,
    payload: { user, settings },
  };
}

export function fetchCurrentUserFailure(): AccountActionTypes {
  return {
    type: actionsTypes.FETCH_CURRENT_USER_FAILURE,
  };
}

export function updateUsersettings(settings: Settings): AccountActionTypes {
  return {
    type: actionsTypes.UPDATE_USER_SETTINGS,
    payload: settings
  };
}

export function updateUsersettingsSuccess(settings: Settings): AccountActionTypes {
  return {
    type: actionsTypes.UPDATE_USER_SETTINGS_SUCCESS,
    payload: settings
  };
}

export function updateUsersettingsFailure(): AccountActionTypes {
  return {
    type: actionsTypes.UPDATE_USER_SETTINGS_FAILURE
  };
}

export function clearAccount() : AccountActionTypes {
  return {
    type: actionsTypes.CLEAR_ACCOUNT
  }
}