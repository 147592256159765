import { PageLayout } from 'components/Layout/PageLayout';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { ReactComponent as MobilePhoto } from 'icons/undraw_mobile_photos.svg';
import { groupBy, orderBy } from 'lodash';
import moment from 'moment';
import { useFetchProject } from '../hooks/useFetchProject';
import { useFetchProjectPhotos } from 'modules/media/hooks/useFetchProjectPhotos';
import { useFetchProjectVideos } from 'modules/media/hooks/useFetchProjectVideos';
import { UploadProjectMediaViewer } from 'modules/media/components/upload-project-media-viewer';
import { ProjectMediaThumbnail } from 'modules/media/components/project-media-thumbnail';
import { defineMessages, useIntl } from 'react-intl';
import { Box, Button, Container, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import VideocamIcon from '@material-ui/icons/Videocam';
import { BackBar } from 'components/BackBar';
import { SecureImage } from 'components/SecureImage';
import { useCallbackRef } from 'use-callback-ref';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile, isAndroid } from 'react-device-detect';
import { GetMapsUrl } from 'helpers/maps';
import { ProjectMedias } from '../components/project-medias/ProjectMedias';
import { MediaThumbnail, MediaType } from '../types';
import { cleanMediaQueue, queueMedia } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { DownloadOptionsMenu } from '../components/download-options-menu';

interface Params {
  projectId: string;
}

interface StateType {
  takePicture?: boolean
}

const m = defineMessages({
  noPictures: {
    id: 'ProjectPage.NoPictures',
    defaultMessage: "Il n'y a pas de photo ou vidéo dans ce projet",
  },
  sharePicture: {
    id: 'ProjectPage.SharePicture',
    defaultMessage: "Soyez le premier à partager une photo ou une vidéo",
  },
  takeAPicture: {
    id: 'ProjectPage.TakeAPicture',
    defaultMessage: "Prendre une photo",
  },
  takeAVideo: {
    id: 'ProjectPage.TakeAVideo',
    defaultMessage: "Prendre une vidéo",
  },
  openMap: {
    id: 'ProjectPage.OpenMap',
    defaultMessage: "Ouvrir la carte",
  },
  sameDayFormat: {
    id: 'ProjectPage.SameDay',
    defaultMessage: "[Aujourd’hui], LL",
  },
  nextDayFormat: {
    id: 'ProjectPage.NextDay',
    defaultMessage: "[Demain], LL",
  },
  nextWeekFormat: {
    id: 'ProjectPage.NextWeek',
    defaultMessage: "dddd",
  },
  lastDayFormat: {
    id: 'ProjectPage.LastDay',
    defaultMessage: "[Hier], LL",
  },
  lastWeekFormat: {
    id: 'ProjectPage.LastWeek',
    defaultMessage: "dddd [dernier], LL",
  },
  sameElseFormat: {
    id: 'ProjectPage.SameElse',
    defaultMessage: "LL",
  }
});

const useStyles = makeStyles({
  root: {
    paddingRight: 4,
    paddingLeft: 4,
  },
});

export const ProjectPage: React.FC = () => {  
  const params = useParams<Params>();
  const location = useLocation<StateType>();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const uploadQueue = useSelector((state: RootState) => state.project.uploadQueue);

  const { project, isFetched: isProjectFetched } = useFetchProject(params.projectId);
  const { projectPhotos, isFetched: isProjectPhotosFetched } = useFetchProjectPhotos(params.projectId);
  const { projectVideos, isFetched: isProjectVideosFetched } = useFetchProjectVideos(params.projectId);

  const [,forceUpdate] = useState(0);
  const { takePicture } = location.state || { takePicture: false };
  const takePictureRef = useCallbackRef<HTMLInputElement>(null, () => forceUpdate(1));

  const handleMultimediaUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if(event.currentTarget.files && event.currentTarget.files.length > 0) {
      Array.from(event.currentTarget.files).forEach((file, index) => {
        dispatch(queueMedia({
          projectId: params.projectId, 
          fileUrl: URL.createObjectURL(file),
          fileName: file.name,
          fileType: file.type
        }));
      });
    }
  }

  useEffect( () => {
    dispatch(cleanMediaQueue(params.projectId));

    if(takePicture && takePictureRef?.current !== null){
      takePictureRef?.current?.click();
      history.replace({ ...location, state: undefined });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takePicture, takePictureRef.current ])

  const projectPhotosThumbnails = projectPhotos.reduce<MediaThumbnail[]>((previousValue, currentValue, currentIndex) => {

    previousValue.push({
      uploadDate: currentValue.uploadDate,
      url: currentValue.lowResolutionUrl,
      previewUrl: currentValue.lowResolutionUrl,
      fileName: currentValue.highResolutionUrl.substring(currentValue.highResolutionUrl.lastIndexOf('/') + 1),
      mediaType: MediaType.Image
    });

    return previousValue;
  }, []);

  const projectVideosThumbnails = projectVideos.reduce<MediaThumbnail[]>((previousValue, currentValue, currentIndex) => {

    previousValue.push({
      uploadDate: currentValue.uploadDate,
      url: currentValue.url,
      previewUrl: "/images/square-video.png",
      fileName: currentValue.url.substring(currentValue.url.lastIndexOf('/') + 1),
      mediaType: MediaType.Video
    });

    return previousValue;
  }, []);

  const projectThumbnails = projectPhotosThumbnails.concat(projectVideosThumbnails);
  const projectThumbnailsOrderedByDate = orderBy(projectThumbnails, (thumbnail) => thumbnail.uploadDate, "desc");  
  const projectThumbnailsGroupByDate = groupBy(projectThumbnailsOrderedByDate, x => moment.utc(x.uploadDate).local().format(moment.HTML5_FMT.DATE));  
  const projectThumbnailsGroupByDateOrdered = orderBy(projectThumbnailsGroupByDate, (i => i[0].uploadDate), ['desc']);
  
  const hasProjectPicturesToday = projectThumbnailsGroupByDateOrdered.length > 0 && moment.utc(projectThumbnailsGroupByDateOrdered[0][0].uploadDate).local().isSame(new Date(), "day");

  const isLoaded = isProjectFetched && isProjectPhotosFetched && isProjectVideosFetched;
  const heroImage = projectPhotos.length > 0 ? projectPhotos[0].lowResolutionUrl : isLoaded ? "/images/project-placeholder.jpg" : "";

  const mapUrl = GetMapsUrl(project?.clientAddressLatitude, project?.clientAddressLongitude, isMobile && isAndroid);

  const currentProjectUploadMedias = uploadQueue.filter(m => m.projectId === params.projectId);

  return (
    <PageLayout>
      <BackBar />
      {isLoaded && (
        <>
          <Box className="tw-relative" style={{height: 150}}>
            <SecureImage src={heroImage} imageStyle={{height: 150}} cover style={{height: 150, width:'100%', padding:0, position:'absolute', zIndex:-1}} />
              <Grid container direction="column" justify="center" className="tw-h-full">
                <Box>
                  <Typography component="h2" variant="h6" className="tw-my-2 tw-py-1 tw-pl-6 tw-pr-2 tw-text-white tw-bg-tertiary tw-bg-opacity-90 tw-w-max">
                    {`${project?.contractNumber === null ? "" : project?.contractNumber + " - "} ${project?.clientFirstName} ${project?.clientLastName}`.trimStart()}
                  </Typography>
                </Box> 
                <Box className="tw-py-1 tw-pl-6 tw-pr-2 tw-text-white tw-bg-tertiary tw-bg-opacity-90 tw-w-max">
                  <Typography variant="body2">{project?.clientAddressStreet}</Typography>
                  <Typography variant="body2">{project?.clientAddressCity}, {project?.clientAddressProvince} {project?.clientAddressPostalCode}</Typography>
                </Box>
              </Grid>
              <Box className="tw-absolute tw-bottom-0 tw-right-0 tw-m-2">
                <Grid container spacing={2}>
                  <Grid item>
                    {mapUrl !== "" && (
                    <IconButton color="primary" aria-label={formatMessage(m.openMap)} component="a" className="tw-bg-white" size="small" href={mapUrl} target="_blank">
                      <MapIcon />
                    </IconButton>
                    )}
                  </Grid>
                  <Grid item>
                    <input type="file" accept="image/*" multiple hidden onChange={handleMultimediaUpload} id="icon-takepicture-file" ref={takePictureRef}/>
                    <label htmlFor="icon-takepicture-file" className="tw-mt-2">
                      <IconButton color="primary" aria-label="upload picture" component="span" className="tw-bg-white" size="small">
                        <PhotoCameraIcon />
                      </IconButton>
                    </label>
                  </Grid>
                  <Grid item>
                    <input type="file" accept="video/*" hidden onChange={handleMultimediaUpload} id="icon-takevideo-file"/>
                    <label htmlFor="icon-takevideo-file" className="tw-mt-2">
                      <IconButton color="primary" aria-label="upload video" component="span" className="tw-bg-white" size="small">
                        <VideocamIcon />
                      </IconButton>
                    </label>
                  </Grid>
                  <Grid item>
                    <DownloadOptionsMenu projectId={params.projectId} medias={projectThumbnails} isFullProject={true} />
                  </Grid>
                </Grid>
              </Box>
          </Box>
          <Container maxWidth="lg" className="tw-flex tw-flex-col tw-mt-2" classes={{ root: classes.root }}>
            <Typography component="h2" variant="h6" color="primary">Photos/Vidéos</Typography>
            {!hasProjectPicturesToday && currentProjectUploadMedias !== undefined && currentProjectUploadMedias?.length > 0 && (
              <ProjectMedias title={moment.utc().local().calendar(null, {sameDay: formatMessage(m.sameDayFormat)})} projectId={params.projectId} medias={[]}>
                {Array.from(currentProjectUploadMedias).map((uploadProjectMedia, index) => {
                  return ( 
                    <Grid item key={index}>
                      <UploadProjectMediaViewer projectId={params.projectId} uploadMedia={uploadProjectMedia} key={index} />
                    </Grid>
                  )
                })}
              </ProjectMedias>
            )}
            
            {Object.entries(projectThumbnailsGroupByDateOrdered).map(([date, thumbnails], index) => {
                return (
                    <ProjectMedias key={index} title={moment.utc(thumbnails[0].uploadDate).local().calendar(null, {
                      sameDay: formatMessage(m.sameDayFormat),
                      nextDay: formatMessage(m.nextDayFormat),
                      nextWeek: formatMessage(m.nextWeekFormat),
                      lastDay: formatMessage(m.lastDayFormat),
                      lastWeek: formatMessage(m.lastWeekFormat),
                      sameElse: formatMessage(m.sameElseFormat),
                  })} projectId={params.projectId} medias={thumbnails}> 
                    {moment.utc(thumbnails[0].uploadDate).local().isSame(new Date(), "day") && (
                      <>
                        {currentProjectUploadMedias !== undefined && Array.from(currentProjectUploadMedias).map((uploadProjectMedia, index) => {
                          return ( 
                            <UploadProjectMediaViewer projectId={params.projectId} uploadMedia={uploadProjectMedia} key={index} />
                          )
                        })}
                      </>
                    )}
                    {thumbnails.map((thumbnail, index) => {
                        return ( 
                          <ProjectMediaThumbnail projectId={params.projectId} mediaUrl={thumbnail.url} previewUrl={thumbnail.previewUrl} key={index} />
                        )
                    })}
                  </ProjectMedias>
                )
            })}
          </Container>
          {((currentProjectUploadMedias === undefined || currentProjectUploadMedias.length === 0) && projectThumbnailsGroupByDateOrdered.length === 0) && (
            <Grid container direction="column" alignItems="center" justify="center" className="tw-flex tw-flex-grow">
              <Typography component="h2" variant="h6">{formatMessage(m.noPictures)}</Typography>
              <MobilePhoto className="tw-w-2/5 tw-my-2 tw-max-w-lg" />
              <Typography>{formatMessage(m.sharePicture)}</Typography>
              <input type="file" accept="image/*" multiple hidden onChange={handleMultimediaUpload} id="icon-button-file"/>
              <label htmlFor="icon-button-file" className="tw-mt-2">
                <Button variant="contained" color="primary" component="span" startIcon={<PhotoCameraIcon />}>{formatMessage(m.takeAPicture)}</Button>
              </label>
              <input type="file" accept="video/*" hidden onChange={handleMultimediaUpload} id="icon-button-video"/>
              <label htmlFor="icon-button-video" className="tw-mt-2">
                <Button variant="contained" color="primary" component="span" startIcon={<VideocamIcon />}>{formatMessage(m.takeAVideo)}</Button>
              </label>
            </Grid>
          )}       
        </>
      )}
      {!isLoaded && ( 
        <LinearProgress />
      )}
    </PageLayout>
  );
};

