import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { History } from 'history';

export const configureApplicationInsights = (history: History) => {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
            extensions: [reactPlugin],
            extensionConfig: {
            [reactPlugin.identifier]: { history: history }
            }
        }
    });
    appInsights.loadAppInsights();

    return reactPlugin;
};