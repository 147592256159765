import * as actionsTypes from './actionTypes';

export interface User {
  name: string;
}

export interface Settings {
  uploadOriginalPhoto: boolean;
}

export enum State {
  notLoaded,
  loading,
  loaded,
  error
}

export interface AccountState {
  user: User | undefined;
  settings: Settings | undefined;
  settingsState: State;
  isAuthenticated: boolean;
}

interface FetchCurrentUserAction {
  type: typeof actionsTypes.FETCH_CURRENT_USER;
}

interface FetchCurrentUserSuccessAction {
  type: typeof actionsTypes.FETCH_CURRENT_USER_SUCCESS;
  payload: { user: User, settings: Settings };
}

interface FetchCurrentUserFailureAction {
  type: typeof actionsTypes.FETCH_CURRENT_USER_FAILURE;
}

interface UpdateUserSettingsAction {
  type: typeof actionsTypes.UPDATE_USER_SETTINGS;
  payload: Settings ;
}

interface UpdateUserSettingsSuccessAction {
  type: typeof actionsTypes.UPDATE_USER_SETTINGS_SUCCESS;
  payload: Settings ;
}

interface UpdateUserSettingsFailureAction {
  type: typeof actionsTypes.UPDATE_USER_SETTINGS_FAILURE;
}

interface ClearAccountAction {
  type: typeof actionsTypes.CLEAR_ACCOUNT;
}

export type AccountActionTypes =
  | FetchCurrentUserAction
  | FetchCurrentUserSuccessAction
  | FetchCurrentUserFailureAction
  | UpdateUserSettingsAction
  | UpdateUserSettingsSuccessAction
  | UpdateUserSettingsFailureAction
  | ClearAccountAction;
