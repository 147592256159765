import Compressor from "compressorjs";
import heic2any from "heic2any";
import { load, dump, insert, remove } from "deskfy-piexifjs";

export const BlobToBase64 = (file:File|Blob) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
});

export const Base64ToBlob = (base64:string) => new Promise<Blob>((resolve, reject) => {

    var mime = base64.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    let contentType = (mime && mime.length) ? mime[1] : '';
    let sliceSize = 512;

    var cleanBase64 = base64.indexOf("base64,") > 0 ? base64.split("base64,")[1] : base64;
    var byteCharacters = atob(cleanBase64);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  resolve(blob);
});

export const tryConvertImage = (blob: Blob) => new Promise<Blob>(async (resolve, reject) => {
    await heic2any({blob: blob, toType: "image/jpeg", quality: 0.8}).then((convertedBlob) => {
        resolve(convertedBlob as Blob);
    })
    .catch((error) => {
        resolve(blob);
    })
});

export const tryExtractExif = async (blob: Blob) => new Promise<any>(async (resolve, reject) => {
    try{
        const originalBase64 = await BlobToBase64(blob);
        const exifObj = load(originalBase64);
        const result = dump(exifObj);
        resolve(result);
    }
    catch(error){
        resolve(undefined); 
    }
});

export const attachExif = async (file: Blob, exifStr: any) => {
    const newBase64 = await BlobToBase64(file);
    const newBase64WithExif = insert(exifStr, newBase64);
    return await Base64ToBlob(newBase64WithExif);
};

export const compressImage = async (base64: string) => new Promise<Blob>(async (resolve, reject) => {
    try{
        var base64WithoutExif = remove(base64)
        var blobWithoutExif = await Base64ToBlob(base64WithoutExif);

        new Compressor(blobWithoutExif, {
            quality: 0.8,
            checkOrientation: false,
            maxHeight: 4032,
            maxWidth: 4032,
            async success(result) {
                resolve(result);
            },
            error(error) {
                reject(error);
            },
        });
    }
    catch(e) {
        reject(e);
    }
});
