
export const GetMapsUrl = (latitude: number | undefined, longitude: number | undefined, isMobileAndroid: boolean) => {
    var url = "";

    if(latitude && longitude){
        url = isMobileAndroid ? 
        `geo:${latitude},${longitude}?q=${latitude},${longitude}` :
        `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    }

    return url;
};