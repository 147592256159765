import { Project, ProjectWithDistance } from 'modules/project/types';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Box, Card, CardActionArea, CardActions, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { SecureImage } from 'components/SecureImage';

const m = defineMessages({
  metres: {
    id: 'ProjectCard.Meters',
    defaultMessage: '{distance, plural, =0 {1 mètre} =1 {1 mètre} other {{distance} mètres}}',
  }
});

export interface ProjectCardProps {
  project: Project;
  lastActivity?: Date;
}

export const ProjectCard: React.FC<ProjectCardProps> = (props: ProjectCardProps) => {  
  const { formatMessage } = useIntl();

  const { project } = props;  

  const isProjectWithDistance = (project: Project): project is ProjectWithDistance => {
    return 'distance' in project;
  }

  const projectFullName = `${project?.contractNumber === null ? "" : project?.contractNumber + " - "} ${project?.clientFirstName} ${project?.clientLastName}`.trimStart();

  return (
    <Card style={{height:100}} className="tw-my-2">
      <CardActionArea component={Link} to={`/projects/${project.id}`}>
        <Grid container direction="row" alignItems="center" className="tw-flex-nowrap">
          {isProjectWithDistance(project) && (
            <Box className="tw-absolute tw-top-0 tw-right-0 tw-mt-0 tw-bg-blue-300">
              <Typography variant="body2" className="tw-text-white tw-px-1">{formatMessage(m.metres, {distance: project.distance})}</Typography>
            </Box>
          )}
          {!isProjectWithDistance(project) && props.lastActivity && (
            <Box className="tw-absolute tw-top-0 tw-right-0 tw-mt-0 tw-bg-blue-300">
              <Typography variant="body2" className="tw-text-white tw-px-1">{moment.utc(props.lastActivity).fromNow()}</Typography>
            </Box>
          )}
          <SecureImage src={`/api/projects/${project.id}/cover`} imageStyle={{height: 100, width: 100}} cover style={{height: 100, width: 100, padding:0, flexShrink:0}} />
          <CardContent className="tw-flex tw-flex-grow tw-p-2">
            <Box>
              <Typography variant="body1" component="h3" className="tw-font-semibold">{projectFullName}</Typography>
              <Box>
                  <Typography variant="body2" className="tw-leading-none">{project.clientAddressStreet}</Typography>
                  <Typography variant="body2" className="tw-leading-none">{project.clientAddressCity}, {project.clientAddressProvince} {project.clientAddressPostalCode}</Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions>
              {isMobile && (
                <IconButton className="tw-bg-primary tw-text-white" size="small" component={Link} to={{ pathname: `/projects/${project.id}`, state:{ takePicture: true }}}>
                  <CameraIcon />
                </IconButton>
              )}
              {!isMobile && (
                <>
                  <IconButton className="tw-bg-primary tw-text-white" size="small" component={Link} to={{ pathname: `/projects/${project.id}`, state:{ takePicture: true }}}>
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </>
              )}
          </CardActions>
        </Grid>
      </CardActionArea>
    </Card>
  );
};
