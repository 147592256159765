import { useState, useEffect, useCallback } from "react";

export const useCurrentLocation = () => {
  const [location, setLocation] = useState<GeolocationPosition>();
  const [error, setError] = useState<GeolocationPositionError>();

  const posititionCallback = (position: GeolocationPosition) => {
      setLocation(position);
  };

  const errorCallback = (positionError: GeolocationPositionError) => {
    setError(positionError);
  };

  const tryLowAccuracyCallback = useCallback(() => {
    navigator.geolocation.getCurrentPosition(posititionCallback, errorCallback, {enableHighAccuracy: false, maximumAge:0, timeout: 10000});
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(posititionCallback, tryLowAccuracyCallback, {enableHighAccuracy: true, maximumAge:0, timeout: 10000});
    }
  }, [tryLowAccuracyCallback]);

  
  const refreshMap = () => {
    navigator.geolocation.getCurrentPosition(posititionCallback, tryLowAccuracyCallback, {enableHighAccuracy: true, maximumAge:0, timeout: 10000});
  }

  return { location, error,  isSupported: navigator.geolocation, refreshMap};
};
