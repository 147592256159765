import { Backdrop, CircularProgress, Container, createStyles, Grid, IconButton, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, MenuProps, Theme, Typography, withStyles } from "@material-ui/core";
import React from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { defineMessages, useIntl } from "react-intl";
import { MediaThumbnail, MediaType } from "modules/project/types";
import { MediaApi } from "modules/media/api";
import { defaultErrorHandler } from "api";
import moment from "moment";

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props: JSX.IntrinsicAttributes & MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const m = defineMessages({
    downloadAll: {
        id: 'DownloadOptionsMenu.downloadAll',
        defaultMessage: 'Tout télécharger',
    },
    downloadAllPhotos: {
        id: 'DownloadOptionsMenu.downloadAllPhotos',
        defaultMessage: 'Télécharger toutes les photos',
    },
    downloadAllVideos: {
        id: 'DownloadOptionsMenu.downloadAllVideos',
        defaultMessage: 'Télécharger toutes les vidéos',
    },
    preparingDownload: {
        id: 'ProjectMedias.preparingDownload',
        defaultMessage: 'Préparation du téléchargement',
    },
});

export interface DownloadOptionsMenuProps {
    projectId: string;
    medias: MediaThumbnail[];
    isFullProject: boolean;
}

export const DownloadOptionsMenu: React.FC<DownloadOptionsMenuProps> = (props: DownloadOptionsMenuProps) => {

    const { formatMessage } = useIntl();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [isDownloading, setIsDownloading] = React.useState(false);
    const classes = useStyles();
    
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const download = (fileNames: string[], downloadName: string) => {
        setIsDownloading(true);
        MediaApi.downloadMedias(props.projectId, fileNames)
        .then((response) => {
            const downloadUrl = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', downloadName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
            defaultErrorHandler(error);
          })
        .finally(() => {
            setIsDownloading(false);
        });
    };

    const handleDownloadAll = () => {
        handleClose();
        const fileNames = props.medias.map(x => x.fileName);
        const downloadFileName = `${props.projectId}${props.isFullProject ? "" : moment.utc(props.medias[0].uploadDate).local().format(" (DD-MM-YYYY)")}.zip`;
        download(fileNames, downloadFileName);
    };

    const handleDownloadAllPhotos = () => {
        handleClose();
        const fileNames = props.medias.filter(x => x.mediaType === MediaType.Image).map(x => x.fileName);
        const downloadFileName = `photos ${props.projectId}${props.isFullProject ? "" : moment.utc(props.medias[0].uploadDate).local().format(" (DD-MM-YYYY)")}.zip`;
        download(fileNames, downloadFileName);
    };

    const handleDownloadAllVideos = () => {
        handleClose();
        const fileNames = props.medias.filter(x => x.mediaType === MediaType.Video).map(x => x.fileName);
        const downloadFileName = `videos ${props.projectId}${props.isFullProject ? "" : moment.utc(props.medias[0].uploadDate).local().format(" (DD-MM-YYYY)")}.zip`;
        download(fileNames, downloadFileName);
    };

    const hasMedias = props.medias.length > 0;
    const hasPhotoMedias = props.medias.some(x => x.mediaType === MediaType.Image);
    const hasVideoMedias = props.medias.some(x => x.mediaType === MediaType.Video);

    return (
        <>
            {hasMedias && (
            <>
                <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                className={props.isFullProject ? "tw-bg-white" : ""}
                size={props.isFullProject ? 'small' : undefined}
                >
                    <MoreVertIcon />
                </IconButton>
                <StyledMenu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                >
                    <MenuItem onClick={handleDownloadAll}>
                        <ListItemIcon>
                            <CloudDownloadIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={formatMessage(m.downloadAll)} />
                    </MenuItem>
                    {(hasPhotoMedias && hasVideoMedias) && (
                        <MenuItem onClick={handleDownloadAllPhotos}>
                            <ListItemIcon>
                                <PhotoLibraryIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={formatMessage(m.downloadAllPhotos)} />
                        </MenuItem>
                    )}
                    {(hasPhotoMedias && hasVideoMedias) && (
                        <MenuItem onClick={handleDownloadAllVideos}>
                            <ListItemIcon>
                                <VideoLibraryIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={formatMessage(m.downloadAllVideos)} />
                        </MenuItem>
                    )}
                </StyledMenu>
            </>
        )} 
            <Backdrop className={classes.backdrop} open={isDownloading}>
                <Container maxWidth="lg">
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Typography variant="h2" align="center" className="tw-mb-4">{formatMessage(m.preparingDownload)}</Typography>
                        <CircularProgress color="inherit" />
                    </Grid>
                </Container>
            </Backdrop> 
        </>
    );
}