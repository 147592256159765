import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';

const m = defineMessages({
  text: {
    id: 'backbar.text',
    defaultMessage: 'Retour',
  },
});

interface Props {
  onDelete?: () => void;
}
export const BackBar: React.FC<Props> = ({ onDelete }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const handleDeleteButtonClick = () => onDelete && onDelete();

  return (
    <Grid container className='tw-bg-white tw-px-6 tw-py-1' alignItems='center'>
      <Button color='secondary' startIcon={<ArrowBackIcon />} onClick={handleBackButtonClick}>
        {formatMessage(m.text)}
      </Button>
      {onDelete && (
        <IconButton aria-label='delete' onClick={handleDeleteButtonClick} className='tw-ml-auto'>
          <DeleteIcon />
        </IconButton>
      )}
    </Grid>
  );
};
