import { defaultErrorHandler } from 'api';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ProjectsApi } from '../api';
import { Project } from '../types';

export interface ProjectsWithLastActivity {
    project: Project,
    lastActivityDate: Date
}

interface Output {
    projectsWithLastActivity: ProjectsWithLastActivity[];
    isFetched: boolean;
}
  
export const useFetchActiveProjects = (days: number | undefined) : Output => {

    const [projectsWithLastActivity, setProjectsWithLastActivity] = useState<ProjectsWithLastActivity[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        
        ProjectsApi.getActive(days, source)
        .then((fetchedProjects) => {
            setProjectsWithLastActivity(fetchedProjects);
            setIsFetched(true);
        })
        .catch((error) => {
            setProjectsWithLastActivity([]);
            setIsFetched(false);

            if(!axios.isCancel(error)){
                defaultErrorHandler(error);
            }
        });

        return() => {
            source.cancel();
        };

    }, [days]);

    return { projectsWithLastActivity, isFetched };
}