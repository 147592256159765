import { PageLayout } from 'components/Layout/PageLayout';
import { defineMessages, useIntl } from 'react-intl';
import React, { useState, useRef, ChangeEvent } from 'react';
import { ProjectsSearch } from '../components/projects-search/ProjectsSearch';
import { debounce } from 'lodash';
import { Box, Container, InputBase, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const m = defineMessages({
  title: {
      id: 'Projects.Title',
      defaultMessage: 'Projets',
  },
  searchPlaceholder: {
    id: 'Projects.SearchPlaceholder',
    defaultMessage: 'Rechercher un projet...',
  },
  searchToggleActiveOnly: {
    id: 'Projects.ToggleActiveOnly',
    defaultMessage: 'Projets en cours seulement',
  }, 
});

export const ProjectsPage: React.FC = () => {  
  const { formatMessage } = useIntl();

  const [query, setQuery] = useState<string>("");
  const delayedQuery = useRef(debounce(q => setQuery(q), 800)).current;

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    delayedQuery(event.currentTarget.value);
  }

  return (
    <PageLayout>
      <Box className="tw-bg-tertiary tw-py-10">
        <Container maxWidth="lg">
          <Paper className="tw-px-2 tw-py-1 tw-flex tw-justify-between tw-items-center">
            <InputBase
              className="tw-flex tw-flex-grow"
              placeholder={formatMessage(m.searchPlaceholder)}
              inputProps={{ 'aria-label': `${formatMessage(m.searchPlaceholder)}}` }}
              onChange={onSearchChange}
            />
            <SearchIcon />
          </Paper>
        </Container>
      </Box>        
      <ProjectsSearch query={query} activeOnly={true}/>
    </PageLayout>
  );
};
