import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

export const PrivateRoute: React.FC<RouteProps> = (props) => {

  const msalIsAuthenticated = useIsAuthenticated();
  const accountIsAuthenticated = useSelector((state: RootState) => state.account.isAuthenticated);

  const isAuthenticated = msalIsAuthenticated && accountIsAuthenticated;

  return (
    <Route
      render={({ location }) =>
        isAuthenticated ? (
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
