import * as actionsTypes from "./actionTypes";
import { take, fork, actionChannel, call, ActionPattern, takeEvery, put } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga';
import { MediaType, ProjectMedia, ProjectPhoto, ProjectVideo } from "./types";
import { mediaUploaded, mediaUploadFailed, uploadMedia } from './actions';
import { PhotosApi, VideosApi } from "modules/media/api";
import { attachExif, BlobToBase64, compressImage, tryConvertImage, tryExtractExif } from "helpers/images";
import { isMobile } from "react-device-detect";

function* uploadVideo(projectId: string, blob: Blob, fileName: string){
    const uploadedVideo: ProjectVideo[] = yield call(VideosApi.uploadVideo, projectId, blob, fileName);

    yield put(mediaUploaded({
        fileName: fileName,
        projectId: projectId,
        media: {
            uploadDate: uploadedVideo[0].uploadDate,
            previewUrl: "/images/square-video.png",
            url: uploadedVideo[0].url,
            fileName: fileName,
            mediaType: MediaType.Video
        }
    }));
}

function* uploadPhoto(projectId: string, blob: Blob, fileName: string){
    const uploadedPhoto: ProjectPhoto[] = yield call(PhotosApi.uploadPhoto, projectId, blob, fileName);
            
    yield put(mediaUploaded({
        fileName: fileName,
        projectId: projectId,
        media: {
            uploadDate: uploadedPhoto[0].uploadDate,
            previewUrl: uploadedPhoto[0].lowResolutionUrl,
            url: uploadedPhoto[0].lowResolutionUrl,
            fileName: fileName,
            mediaType: MediaType.Image
        }
    }));
}

function* handleMediaUpload(payload: ProjectMedia) {

    try{
        const localBlob: Blob = yield fetch(payload.fileUrl).then(async r => {
            const data = await r.blob();
            return data;
        });

        if (payload.fileType.includes('video') || payload.fileName.endsWith('.hevc')) {
            yield uploadVideo(payload.projectId, localBlob, payload.fileName);
        }
        else {
            const exifStr: unknown = yield call(tryExtractExif, localBlob);
            const convertedFile: Blob = yield call(tryConvertImage, localBlob);
            const base64: string = yield call(BlobToBase64, convertedFile);

            let blobToUpload = convertedFile;
            if(isMobile) {
                try {
                    blobToUpload = yield call(compressImage, base64);
                }
                catch {
                    // Do nothing
                }
                if(exifStr) {
                    blobToUpload = yield call(attachExif, blobToUpload, exifStr);
                }
            }

            yield uploadPhoto(payload.projectId, blobToUpload, payload.fileName);
        }
    }
    catch(error) {
        yield put(mediaUploadFailed(payload));
    }
    finally{
        URL.revokeObjectURL(payload.fileUrl)
    }
}

function* watchQueue(type: ActionPattern) {
    const requestChan: ActionPattern = yield actionChannel(type);
    while (true) {
        const {payload} = yield take(requestChan)
        yield call(handleMediaUpload, payload);
    }
}

function* queueMedia(action: any) {
    yield put(uploadMedia(action.payload as ProjectMedia));
}

export default function* projectSagas(): SagaIterator {
  yield fork(watchQueue, actionsTypes.UPLOAD_MEDIA);
  yield takeEvery(actionsTypes.QUEUE_MEDIA, queueMedia);
}

