import { useEffect, useState } from 'react';
import { defaultErrorHandler } from 'api';
import { ProjectMediaDetails } from 'modules/project/types';
import { MediaApi } from '../api';
import axios from 'axios';

interface Output {
    projectMediasDetails: ProjectMediaDetails[] | undefined;
    isFetched: boolean;
}
  
export const useFetchProjectMediasDetails = (projectId: string ) : Output => {

    const [projectMediasDetails, setProjectMediasDetails] = useState<ProjectMediaDetails[]>();
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        
        MediaApi.getMediasDetails(projectId, source)
        .then((fetchedProjectMediasDetails) => {
            setProjectMediasDetails(fetchedProjectMediasDetails);
            setIsFetched(true);
        })
        .catch((error) => {
            setProjectMediasDetails(undefined);
            setIsFetched(false);
            defaultErrorHandler(error);
        });

        return() => {
            source.cancel();
        };
    }, [projectId]);

    return { projectMediasDetails, isFetched };
}