import * as actionsTypes from './actionTypes';
import { User } from "modules/account"

export type Project = {
  id : string;
  opportunityId : string;
  contractNumber : string;
  projectType : ProjectType;
  clientFirstName : string;
  clientLastName: string;
  clientMobilePhone: string;
  clientTelephone: string;
  clientEmailAddress: string;
  clientAddressLatitude: number;
  clientAddressLongitude: number;
  clientAddressStreet: string;
  clientAddressCity: string;
  clientAddressProvince: string;
  clientAddressCountry: string;
  clientAddressPostalCode: string;
}

export type ProjectPhoto = {
  lowResolutionUrl: string;
  highResolutionUrl: string;
  uploadDate: Date
}

export type ProjectVideo = {
  uploadDate: Date
  url: string
}

export type ProjectMediaDetails = {
  mediaType: MediaType;
  createDate?: Date;
  uploadDate: Date
  projectId: string;
  clientName: string;
  author: User;
  latitude?: number;
  longitude?: number;
  url: string;
  downloadUrl: string;
  name: string;
}

export type Activity = {
  applicationUser: User;
  projectId: string;
  project: Project;
  date: Date;
  activityType: ActivityType;
  mediaCount: number;
}

export interface ActivityWithCount extends Activity {
  count: number;
};

export interface ProjectWithDistance extends Project {
  distance: number;
};

export interface MediaThumbnail {
  uploadDate: Date;
  url: string;
  previewUrl: string;
  fileName: string;
  mediaType: MediaType;
}

export enum ProjectType {
  Lead = 0,
  Opportunity = 1,
  Project = 2,
}

export enum ActivityType {
  PhotoUpload = 0,
  VideoUpload = 1,
  PhotoDelete = 2,
  VideoDelete = 3,
  MediaDownload = 4,
}

export enum MediaType {
  Image = 0,
  Video = 1,
}

export enum ProjectQueueMediaStatus {
  uploading,
  uploaded,
  error
}

export interface ProjectState {
  uploadQueue: ProjectQueueMedia[]
}

export interface ProjectQueueMedia {
  projectId: string;
  fileName: string;
  previewUrl: string;
  status: ProjectQueueMediaStatus;
  media?: MediaThumbnail;
}

export interface ProjectMedia {
  projectId: string;
  fileUrl: string;
  fileType: string;
  fileName: string;
}

export interface MediaUploadedItem {
  projectId: string;
  fileName: string;
  media: MediaThumbnail
}

interface QueueMediaAction {
  type: typeof actionsTypes.QUEUE_MEDIA;
  payload: ProjectMedia;
}

interface UploadMediaAction {
  type: typeof actionsTypes.UPLOAD_MEDIA;
  payload: ProjectMedia;
}

interface MediaUploadedAction {
    type: typeof actionsTypes.MEDIA_UPLOADED;
    payload: MediaUploadedItem;
}

interface MediaUploadFailedAction {
  type: typeof actionsTypes.MEDIA_UPLOAD_FAILED;
  payload: ProjectMedia;
}

interface CleanMediaQueueAction {
  type: typeof actionsTypes.CLEAN_MEDIA_QUEUE;
  payload: string;
}

export type ProjectActionTypes =
  | CleanMediaQueueAction
  | QueueMediaAction
  | UploadMediaAction
  | MediaUploadedAction
  | MediaUploadFailedAction;