import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { MSButton } from 'components/MSButton';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import { Box, Container, Grid, Typography } from '@material-ui/core';

const m = defineMessages({
  subtitle: {
    id: 'LoginPage.Subtitle',
    defaultMessage: 'Bienvenue',
  },
  message: {
    id: 'LoginPage.Message',
    defaultMessage: "Veuillez vous identifier pour accéder à l'application",
  },
});

export const ExternalLoginPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { instance } = useMsal();

  const handleOnClick = () => {
    instance.loginRedirect(loginRequest);
  }

  return (
    <Box className="tw-flex tw-flex-col tw-min-h-screen">
      <Box className="tw-flex tw-flex-grow tw-bg-red">
        <Container maxWidth="sm" className="tw-flex tw-flex-col">
          <Grid container direction="column" justify="center" alignItems="center" className="tw-flex tw-flex-grow">
            <img src='/images/be-logo.png' alt="Bisson Expert logo" style={{maxWidth:200}} />
          </Grid>
          <Grid container direction="column" justify="center" alignItems="center" className="tw-flex tw-pb-10">
            <img src='/images/camera.png' alt="camera" width={80} className="tw-mb-4"/>
            <Typography variant="h4" className="tw-text-white" component="h1">CAM BISSON</Typography>
          </Grid>
        </Container>
      </Box>
      <Box>
        <Container maxWidth="sm">
          <Grid container direction="column" alignItems="center" className="tw-flex tw-py-10">
            <Typography variant="h4" component="h2">{formatMessage(m.subtitle)}</Typography>
            <Typography paragraph className="tw-py-10">{formatMessage(m.message)}</Typography>
            <Box className="tw-flex tw-justify-center tw-mt-2">
              <MSButton onClick={handleOnClick} />
            </Box>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
