import React from 'react';
import { TopBar } from './TopBar';
import { BottomBar } from './BottomBar';

export interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = (props: PageLayoutProps) => {
  return (
    <div
      className='tw-h-screen tw-grid'
      style={{ gridTemplateColumns: '1fr', gridTemplateRows: 'auto 1fr auto' }}>
      <div>
        <TopBar />
      </div>
      <div id="page-container" className='tw-overflow-auto'>{props.children}</div>
      <div>
        <BottomBar />
      </div>
    </div>
  );
};
