import { PageLayout } from 'components/Layout/PageLayout';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { BackBar } from 'components/BackBar';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, LinearProgress } from '@material-ui/core';
import { useFetchProjectMediasDetails } from '../hooks/useFetchProjectMediasDetails';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Keyboard, Virtual } from 'swiper/core';
import { MediaViewer } from '../components/MediaViewer';
import { findIndex, orderBy } from 'lodash';
import { isDesktop } from 'react-device-detect';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import './style.scss';
import { MediaApi } from '../api';
import { defaultErrorHandler } from 'api';
import { ProjectMediaDetails } from 'modules/project/types';
import { defineMessages, useIntl } from 'react-intl';

SwiperCore.use([Navigation, Keyboard, Virtual]);

const m = defineMessages({
  deleteMediaPrompt: {
    id: 'MediaPage.DeleteConfirmaiton.Prompt',
    defaultMessage: 'Êtes-vous certain de vouloir supprimer cette image/vidéo ?',
  },
  keep: {
    id: 'MediaPage.DeleteConfirmaiton.Keep',
    defaultMessage: 'Garder',
  },
  delete: {
    id: 'MediaPage.DeleteConfirmaiton.Delete',
    defaultMessage: 'Supprimer',
  },
});

interface Params {
  projectId: string;
  fileName: string;
}

export const MediaPage: React.FC = () => {
  const params = useParams<Params>();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [swiper, setSwiper] = useState<any>(null);

  const { projectMediasDetails, isFetched: isProjectMediasDetailsFetched } =
    useFetchProjectMediasDetails(params.projectId);

  const [projectMediasDetailsOrderedByDate, setProjectMediasDetailsOrderedByDate] = useState<
    ProjectMediaDetails[]
  >([]);

  const [showDeleteMediaConfirmationDialog, setShowDeleteMediaConfirmationDialog] = useState(false);
  const [initialMediaIndex, setInitialMediaIndex] = useState<number>();

  useEffect(() => {
    if (!isProjectMediasDetailsFetched) return;

    const orderedMedias = orderBy(
      projectMediasDetails,
      (projectMediaDetails) => projectMediaDetails.uploadDate,
      'desc'
    );

    setProjectMediasDetailsOrderedByDate(orderedMedias);

    if (initialMediaIndex === undefined) {
      const initialMediaIndex = findIndex(
        orderedMedias,
        (media) => media.name === params.fileName
      );
      
      setInitialMediaIndex(initialMediaIndex);
    }
  }, [initialMediaIndex, isProjectMediasDetailsFetched, params.fileName, projectMediasDetails]);

  useEffect(() => {    
    if (swiper && initialMediaIndex !== undefined) {
      swiper.slideTo(initialMediaIndex >= 0 ? initialMediaIndex : 0);
    }
  }, [initialMediaIndex, swiper]);

  const handleDeleteMedia = () => {
    setShowDeleteMediaConfirmationDialog(true);
  };

  const handleCancelDeleteMedia = () => {
    setShowDeleteMediaConfirmationDialog(false);
  }

  const handleDeleteMediaConfirmed = () => {
    setShowDeleteMediaConfirmationDialog(false);

    const currentMediaIndex = swiper.realIndex;    
    const currentMediaName = projectMediasDetailsOrderedByDate?.[currentMediaIndex];

    if (!currentMediaName) return;

    MediaApi.deleteMedia(params.projectId, currentMediaName.name)
      .then(() => {
        const lastMediaIndex = projectMediasDetailsOrderedByDate.length - 1;
        const wasLastMedia = currentMediaIndex === lastMediaIndex;
        const isCarouselEmpty = projectMediasDetailsOrderedByDate.length === 1;

        if (isCarouselEmpty) {
          history.push(`/projects/${params.projectId}`);
        } else if (wasLastMedia) {
          swiper.slideTo(currentMediaIndex - 1);
        } else {
          swiper.slideTo(currentMediaIndex + 1);
        }

        setProjectMediasDetailsOrderedByDate(
          projectMediasDetailsOrderedByDate.filter(
            (mediaDetail, index) => index !== currentMediaIndex
          )
        );
      })
      .catch((error) => defaultErrorHandler(error));
  }

  const canDelete = Boolean(swiper);

  return (
    <PageLayout>
      <div
        className='tw-h-full tw-grid'
        style={{ gridTemplateColumns: '1fr', gridTemplateRows: 'auto 1fr' }}>
        <BackBar onDelete={canDelete ? handleDeleteMedia : undefined} />
        {isProjectMediasDetailsFetched ? (
          <div className='tw-w-full tw-overflow-x-hidden tw-bg-black'>
            <Swiper
              virtual
              spaceBetween={50}
              onSwiper={setSwiper}
              navigation={isDesktop}
              keyboard={{ enabled: true }}
              className='tw-h-full'>
              {projectMediasDetailsOrderedByDate?.map((projectMediaDetails, index) => (
                <SwiperSlide key={index} className='tw-h-full' virtualIndex={index}>
                  <MediaViewer projectMediaDetails={projectMediaDetails} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <LinearProgress />
        )}
      </div>
      <Dialog
        open={showDeleteMediaConfirmationDialog}
        onClose={handleCancelDeleteMedia}
      >        
        <DialogContent>
          <DialogContentText>
            {formatMessage(m.deleteMediaPrompt)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteMedia} color="primary" autoFocus>
            {formatMessage(m.keep)}
          </Button>
          <Button onClick={handleDeleteMediaConfirmed} color="primary">
          {formatMessage(m.delete)}
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};
