import { AppBar, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import { Link, useLocation } from 'react-router-dom';

export const BottomBar: React.FC = () => {
  const location = useLocation();
  const rootPathName = location.pathname.split('/')[1];

  const [value, setValue] = React.useState(rootPathName);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setValue(value);
  };

  return (
    <AppBar position="static" component='footer'>
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction
          component={Link}
          to={'/'}
          value=''
          label='Accueil'
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to={'/projects'}
          value='projects'
          label='Projets'
          icon={<ListIcon />}
        />
      </BottomNavigation>
    </AppBar>
  );
};
