import { PageLayout } from 'components/Layout/PageLayout';
import { defineMessages, useIntl } from 'react-intl';
import React from 'react';

const m = defineMessages({
    title: {
        id: 'SettingsPage.Title',
        defaultMessage: 'Paramètres',
    },
    save: {
        id: 'SettingsPage.Save',
        defaultMessage: 'Sauvegarder',
    },
    uploadSetting: {
        id: 'SettingsPage.UploadSetting',
        defaultMessage: 'Téléverser les photos originales',
    }
});


export const SettingsPage: React.FC = () => {  
    const { formatMessage } = useIntl();

    const handleSaveButtonOnClick = () => {

    }

    return (
        <PageLayout>
            <h2 className="tw-text-lg tw-font-semibold tw-m-0">{formatMessage(m.title)}</h2>
            <form className="tw-mt-2">
                <button onClick={handleSaveButtonOnClick}>{formatMessage(m.save)}</button>
            </form>
        </PageLayout>
    );
};