import { Reducer } from 'react';
import {
  CLEAR_ACCOUNT,
  FETCH_CURRENT_USER_FAILURE,
  FETCH_CURRENT_USER_SUCCESS,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_SUCCESS,
} from './actionTypes';
import { AccountActionTypes, AccountState, State } from './types';

const initialState: AccountState = {
  user: undefined,
  settings: undefined,
  settingsState : State.notLoaded,
  isAuthenticated: false,
};

export const accountReducer: Reducer<AccountState, AccountActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        settings: action.payload.settings,
        settingsState: State.loaded
      };
    case FETCH_CURRENT_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
        settings: undefined,
        settingsState: State.error
      };
    case UPDATE_USER_SETTINGS:
      return {
        ...state,
        settingsState: State.loading
      };
    case UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        settingsState: State.loaded
      };
    case CLEAR_ACCOUNT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
