import { defaultErrorHandler } from 'api';
import { useEffect, useState } from 'react';
import { ProjectsApi } from '../api';
import { Project } from '../types';

interface Output {
    project: Project | undefined;
    isFetched: boolean;
}
  
export const useFetchProject = (projectId: string) : Output => {

    const [project, setProject] = useState<Project>();
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        ProjectsApi.get(projectId)
        .then((fetchedProject) => {
            setProject(fetchedProject);
            setIsFetched(true);
        })
        .catch((error) => {
            setProject(undefined);
            setIsFetched(false);
            defaultErrorHandler(error);
        });
    }, [projectId]);

    return { project, isFetched };
}