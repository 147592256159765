import { all, fork } from 'redux-saga/effects';
import accountSagas from 'modules/account/sagas';
import { applicationSagas } from 'modules/application/sagas';
import { SagaIterator } from 'redux-saga';
import projectSagas from 'modules/project/sagas';

export default function* rootSaga() : SagaIterator {
  yield all([
    fork(accountSagas), 
    fork(applicationSagas),
    fork(projectSagas),
  ]);
}
