import { defaultApi, requests } from 'api';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { ProjectMediaDetails, ProjectPhoto, ProjectVideo } from 'modules/project/types';

const apiPhotoBaseUrl = '/api/photos';
const apiVideoBaseUrl = '/api/videos';
const apiMediaBaseUrl = '/api/media';



export const PhotosApi = {
  getPhotos: (projectId: string, cancellationToken: CancelTokenSource): Promise<ProjectPhoto[]> => requests.get(`${apiPhotoBaseUrl}/${projectId}`, cancellationToken),
  uploadPhoto: (projectId: string, blob: Blob, name: string = "photo"): Promise<ProjectPhoto[]> => requests.post(`${apiPhotoBaseUrl}/${projectId}`, buildFormData(blob, name))
};

export const VideosApi = {
  getVideos: (projectId: string, cancellationToken: CancelTokenSource): Promise<ProjectVideo[]> => requests.get(`${apiVideoBaseUrl}/${projectId}`, cancellationToken),
  uploadVideo: (projectId: string, blob: Blob, name: string = "video"): Promise<ProjectVideo[]> => requests.post(`${apiVideoBaseUrl}/${projectId}`, buildFormData(blob, name))
};

export const MediaApi = {
  getMediasDetails: (projectId: string, cancellationToken: CancelTokenSource): Promise<ProjectMediaDetails[]> => requests.get(`${apiMediaBaseUrl}/${projectId}/details`, cancellationToken),
  getPhotoMedia: (mediaUrl: string, cancellationToken: CancelTokenSource): Promise<AxiosResponse<any>> => 
  defaultApi.request({
    method: 'GET',
    url: `${mediaUrl}`,
    responseType: 'blob',
    cancelToken: cancellationToken.token
  }),
  downloadMedias: (projectId: string, fileNames: string[]): Promise<AxiosResponse<any>> => 
  defaultApi.request({
    method: 'POST',
    url: `${apiMediaBaseUrl}/${projectId}/download`,
    responseType: 'blob',
    data: fileNames,
  }),
  deleteMedia: (projectId: string, mediaName: string): Promise<any> => requests.del(`${apiMediaBaseUrl}/${projectId}/${mediaName}`)
};

const buildFormData = (blob: Blob, name: string): FormData => {
    defaultApi.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    const formData = new FormData();
    formData.append(name, blob, name);
  
    return formData;
};