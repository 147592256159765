import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RootState } from 'store';
import { SplashScreen } from 'modules/application/views/SplashScreen';
import { HomePage } from 'modules/application/views/HomePage';
import { ProjectsPage } from 'modules/project/views/ProjectsPage';
import { ProjectPage } from 'modules/project/views/ProjectPage';
import { SettingsPage } from 'modules/settings/views/SettingsPage';
import { MediaPage } from 'modules/media/views/MediaPage';
import { ExternalLoginPage } from 'modules/application/views/ExternalLoginPage';
import { startApplication } from 'modules/application/actions';
import { PrivateRoute } from 'modules/application/components/PrivateRoute';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'muiTheme';
import { NotFoundError } from 'modules/errors/views/NotFoundError';
import { InternalServerError } from 'modules/errors/views/InternalServerError';
import { UnauthorizedError } from 'modules/errors/views/UnauthorizedError';
import { ForbiddenError } from 'modules/errors/views/ForbiddenError';

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector((state: RootState) => state.application.isLoaded);

  useEffect(() => {
    dispatch(startApplication());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/projects" component={ProjectsPage} />
          <PrivateRoute exact path="/projects/:projectId" component={ProjectPage} />
          <PrivateRoute exact path="/projects/:projectId/:fileName" component={MediaPage} />
          <PrivateRoute exact path="/settings" component={SettingsPage} />
          
          <Route exact path="/error/404" component={NotFoundError} />
          <Route exact path="/error/500" component={InternalServerError} />
          <Route exact path="/error/401" component={UnauthorizedError} />
          <Route exact path="/error/403" component={ForbiddenError} />

          <Route exact path="/login" component={ExternalLoginPage} />
        </Switch>
      ) : (
        <SplashScreen />
      )}
    </ThemeProvider>
  );
};
