import axios from 'axios';
import Image, { ImageProps } from 'material-ui-image';
import { MediaApi } from 'modules/media/api';
import React, { useEffect } from 'react';
import { useState } from 'react';

export const SecureImage: React.FC<ImageProps> = (props:ImageProps) => {

  const [base64Data, setBase64Data] = useState<string|ArrayBuffer|null>("");
  const { src, ...rest } = props;

  useEffect(() => {
    const source = axios.CancelToken.source();

    MediaApi.getPhotoMedia(props.src, source)
    .then((response) => {
      var reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = function() {
        setBase64Data(reader.result);                
      }
    }).catch((e) => {
      setBase64Data("/images/square-image.png");
    })

    return() => {
      source.cancel();
      setBase64Data(null);
    };
  }, [props.src]);

  return (
    <Image {...rest} src={`${base64Data}`}/>
  );
};
