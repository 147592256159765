import { requests } from 'api';
import { CancelTokenSource } from 'axios';
import { ProjectsWithLastActivity } from './hooks/useFetchActiveProjects';
import { Project } from './types';

const apiBaseUrl = '/api/projects';

export const ProjectsApi = {
  get: (projectId: string): Promise<Project> => requests.get(`${apiBaseUrl}/${projectId}`),
  getAllNear: (latitude: number, longitude: number, cancellationToken: CancelTokenSource): Promise<Project[]> => requests.get(`${apiBaseUrl}/near?latitude=${latitude}&longitude=${longitude}`, cancellationToken),
  search: (query: string, activeOnly: boolean, cancellationToken: CancelTokenSource): Promise<Project[]> => requests.get(`${apiBaseUrl}/search?query=${query}&activeProjectsOnly=${activeOnly}`, cancellationToken),
  getActive: (days: number = 5, cancellationToken: CancelTokenSource): Promise<ProjectsWithLastActivity[]> => requests.get(`${apiBaseUrl}/active?days=${days}`, cancellationToken),
};
