import axios from 'axios';
import { MediaApi } from 'modules/media/api';
import React, { useEffect } from 'react';
import { useState } from 'react';
import InnerImageZoom, { InnerImageZoomProps } from 'react-inner-image-zoom';

export const SecureInnerImageZoom: React.FC<InnerImageZoomProps> = (props:InnerImageZoomProps) => {

  const [base64Data, setBase64Data] = useState<string|ArrayBuffer|null>("/images/square-image.png");
  const [zoomBase64Data, setZoomBase64Data] = useState<string|ArrayBuffer|null>();
  const { src, zoomSrc, ...rest } = props;

  useEffect(() => {
    const source = axios.CancelToken.source();

    MediaApi.getPhotoMedia(props.src, source)
    .then((response) => {
      var reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = function() {
        setBase64Data(reader.result);                
      }
    }).catch((e) => {
      setBase64Data("/images/square-image.png");
    })
    
    return () => {
      source.cancel();
      setBase64Data(null);
      setZoomBase64Data(null);
    };
  }, [props.src]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if(props.zoomSrc){
      MediaApi.getPhotoMedia(props.zoomSrc, source)
      .then((response) => {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function() {
          setZoomBase64Data(reader.result);                
        }
      }).catch((e) => {
        setZoomBase64Data("/images/square-image.png");
      })
    }

    return () => {
      if(props.zoomSrc){
        source.cancel();
      } 
    }
  }, [props.zoomSrc]);

  return (
    <InnerImageZoom {...rest} src={`${base64Data}`} zoomSrc={`${zoomBase64Data}`}/>
  );
};
