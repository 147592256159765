import React from 'react';
import { PageLayout } from 'components/Layout/PageLayout'
import { ProjectsNear } from 'modules/project/components/projects-near'
import { ActivityStream } from 'modules/activity/components/activity-stream'
import { ActiveProjects } from 'modules/project/components/active-projects';
import { Box, Container } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

export const HomePage: React.FC = () => {  

  return (
    <PageLayout>   
          {isMobile && (
            <Container maxWidth="lg" className="tw-flex tw-flex-col tw-mt-4">
                <Box className="tw-mb-4">
                  <ProjectsNear />
                </Box>
                <ActiveProjects />
            </Container>
          )}
          {!isMobile && (
            <ActivityStream />
          )}
        
    </PageLayout>
  );
};
