import { defaultErrorHandler } from 'api';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { ProjectsApi } from '../api';
import { Project } from '../types';
import { orderBy } from 'lodash';
import axios from 'axios';

interface Output {
    projects: Project[];
    isFetched: boolean;
}
  
export const useSearchProjects = (query: string, activeOnly: boolean) : Output => {

    const [projects, setProjects] = useState<Project[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        
        if(!isEmpty(query))
        {
            setIsFetched(false);
            ProjectsApi.search(query, activeOnly, source)
            .then((fetchedProjects) => {
                const projectsOrdered = orderBy(fetchedProjects, (i => i.clientFirstName), ['desc']);
                setProjects(projectsOrdered);
                setIsFetched(true);
            })
            .catch((error) => {
                setProjects([]);
                setIsFetched(false);

                if(!axios.isCancel(error)){
                    defaultErrorHandler(error);
                }
            });
        }
        else{
            setProjects([]);
            setIsFetched(true);
        }

        return() => {
            source.cancel();
        };

    }, [query, activeOnly]);

    return { projects, isFetched };
}