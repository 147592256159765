import { defaultErrorHandler } from 'api';
import axios from 'axios';
import { ProjectPhoto } from 'modules/project/types';
import { useEffect, useState } from 'react';
import { PhotosApi } from '../api';

interface Output {
    projectPhotos: ProjectPhoto[];
    isFetched: boolean;
}
  
export const useFetchProjectPhotos = (projectId: string) : Output => {

    const [projectPhotos, setProjectPhotos] = useState<ProjectPhoto[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();

        PhotosApi.getPhotos(projectId, source)
        .then((fetchedProjectPhotos) => {
            setProjectPhotos(fetchedProjectPhotos);
            setIsFetched(true);
        })
        .catch((error) => {
            setProjectPhotos([]);
            setIsFetched(false);
            if(!axios.isCancel(error)){
                defaultErrorHandler(error);
            }
        });

        return() => {
            source.cancel();
        };

    }, [projectId]);

    return { projectPhotos, isFetched };
}