import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ErrorPage } from "../components/ErrorPage";
import { ReactComponent as ServerDownIcon } from 'icons/undraw_server_down.svg';

const m = defineMessages({
  title: {
    id: 'Errors.InternalServerError.Title',
    defaultMessage: "Oups...il s'est produit une erreur",
  },
  message: {
    id: 'Errors.InternalServerError.Message',
    defaultMessage: "Une erreur s'est produite lors du traitement d'une opération sur le serveur.\nVeuillez réessayer plus tard ou contacter l'administrateur.",
  }
});

export const InternalServerError = () => {
  const { formatMessage } = useIntl();

  return (
    <ErrorPage
      errorCode={500}
      title={formatMessage(m.title)}
      message={formatMessage(m.message)}
      svgImage={<ServerDownIcon />}
    />
  );
};
