import React from 'react';
import { ProjectCard } from 'modules/project/components/project-card';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchProjects } from 'modules/project/hooks/useSearchProjects';
import { ReactComponent as EmptySearch } from 'icons/undraw_the_search.svg';
import { ReactComponent as NewSearch } from 'icons/undraw_Web_search.svg';
import { isEmpty } from 'lodash';
import { Box, Container, Grid, LinearProgress, Typography } from '@material-ui/core';

const m = defineMessages({
  title: {
    id: 'ProjectsSearch.Title',
    defaultMessage: "Résultats",
  },
  emptySearchTitle: {
    id: 'ProjectsSearch.EmptySearchTitle',
    defaultMessage: "Aucun résultat trouvé",
  },
  emptySearchSubTitle: {
    id: 'ProjectsSearch.EmptySearchSubTitle',
    defaultMessage: "Nous n'avons trouvé aucun projet pour votre recherche.",
  },
  startSearchTitle: {
    id: 'ProjectsSearch.StartSearchTitle',
    defaultMessage: "Commencer votre recherche",
  },
  startSearchSubTitle: {
    id: 'ProjectsSearch.StartSearchSubTitle',
    defaultMessage: "Utilisez la barre de recherche pour trouver des projets.",
  }
});

export interface ProjectsSearchProps {
  query: string;
  activeOnly: boolean;
}

export const ProjectsSearch: React.FC<ProjectsSearchProps> = (props: ProjectsSearchProps) => {  
  const { formatMessage } = useIntl();
  const { projects, isFetched: isProjectsFetched } = useSearchProjects(props.query, props.activeOnly);

  return (
    <>
      {isProjectsFetched ? (
        <Container maxWidth="lg" className="tw-flex tw-flex-col tw-mt-4 tw-flex-grow">
          {projects.length > 0 ? (
            <Box className="tw-my-2">
              <Typography component="h2" variant="h6" color="primary">{formatMessage(m.title)}</Typography>
              {projects.map((project, index) => {
                return ( 
                  <ProjectCard key={index} project={project}/>
                )
              })}
            </Box>
          ) : (
            <>
              {isEmpty(props.query) ? (
                <Box className="tw-flex tw-flex-grow">
                  <Grid container direction="column" justify="center" alignItems="center">
                    <Typography align="center" component="h2" variant="h6">{formatMessage(m.startSearchTitle)}</Typography>
                    <NewSearch className="tw-w-2/5 tw-my-2 tw-max-w-lg" />
                    <Typography align="center">{formatMessage(m.startSearchSubTitle)}</Typography>
                  </Grid>
                </Box>
              ) : (
                <Box className="tw-flex tw-flex-grow">
                  <Grid container direction="column" justify="center" alignItems="center">
                    <Typography align="center" component="h2" variant="h6">{formatMessage(m.emptySearchTitle)}</Typography>
                    <EmptySearch className="tw-w-2/5 tw-my-2 tw-max-w-lg" />
                    <Typography align="center">{formatMessage(m.emptySearchSubTitle)}</Typography>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Container>
      ) : (
        <LinearProgress />
      )}
    </> 
  );
};
