import React from 'react';
import { ProjectMediaThumbnail } from '../project-media-thumbnail';
import { ProjectQueueMedia, ProjectQueueMediaStatus } from 'modules/project/types';
import { Box, CircularProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

export interface UploadProjectMediaViewerProps {
    projectId: string,
    uploadMedia: ProjectQueueMedia
}

export const UploadProjectMediaViewer: React.FC<UploadProjectMediaViewerProps> = (props : UploadProjectMediaViewerProps) => {  

    return(
        <div className="tw-relative">
            <>
                {props.uploadMedia.media !== undefined ? (
                    <ProjectMediaThumbnail projectId={props.projectId} previewUrl={props.uploadMedia.media.previewUrl} mediaUrl={props.uploadMedia.media.url} />
                ): (
                    <ProjectMediaThumbnail projectId={props.projectId} previewUrl={props.uploadMedia.previewUrl} className={(props.uploadMedia.status !== ProjectQueueMediaStatus.uploaded ? "tw-filter tw-brightness-50" : "")} />
                )}
                {props.uploadMedia.status === ProjectQueueMediaStatus.uploading && (
                    <Box className="tw-absolute tw-top-0 tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
                        <CircularProgress />
                    </Box>
                )}
                {props.uploadMedia.status === ProjectQueueMediaStatus.error && (
                    <Box className="tw-absolute tw-top-0 tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
                        <ErrorIcon color="secondary" />
                    </Box>
                )}
            </>
        </div>
    )
}