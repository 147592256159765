import { delay, put, race, take, takeEvery } from 'redux-saga/effects';
import * as actionsTypes from './actionTypes';
import { fetchCurrentUser } from 'modules/account/actions';
import { startApplicationCompleted } from './actions';
import { SagaIterator } from 'redux-saga';
import * as accountActionTypes from 'modules/account/actionTypes';

function* startApplication() {
  yield delay(1000);
  yield put(fetchCurrentUser());

  yield race({
    success: take(accountActionTypes.FETCH_CURRENT_USER_SUCCESS),
    failure: take(accountActionTypes.FETCH_CURRENT_USER_FAILURE),
  });
  
  yield put(startApplicationCompleted('fr-CA'));
}

export function* applicationSagas(): SagaIterator {
  yield takeEvery(actionsTypes.START_APPLICATION, startApplication);
}

