import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ErrorPage } from "../components/ErrorPage";
import { ReactComponent as ApplicationErrorIcon } from 'icons/undraw_fixing_bugs.svg';

const m = defineMessages({
  title: {
    id: 'Errors.ApplicationError.Title',
    defaultMessage: "Oups...il s'est produit une erreur",
  },
  message: {
    id: 'Errors.ApplicationError.Message',
    defaultMessage: "Une erreur s'est produite lors du traitement d'une opération.\nVeuillez réessayer plus tard ou contacter l'administrateur.",
  }
});

export const ApplicationError = () => {
  const { formatMessage } = useIntl();

  return (
    <ErrorPage
      title={formatMessage(m.title)}
      message={formatMessage(m.message)}
      svgImage={<ApplicationErrorIcon />}
    />
  );
};
