import React from 'react';
import { Box, Button, Drawer, Grid, IconButton, Typography } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import { isAndroid, isMobile } from 'react-device-detect';
import { MediaType, ProjectMediaDetails } from 'modules/project/types';
import { GetMapsUrl } from 'helpers/maps';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { SecureInnerImageZoom } from 'components/SecureImage';
import { SecureVideo } from 'components/SecureVideo';

const m = defineMessages({
  openMap: {
    id: 'MediaViewer.OpenMap',
    defaultMessage: 'Ouvrir la carte',
  },
  createdLabel: {
    id: 'MediaViewer.CreatedLabel',
    defaultMessage: 'Prise le',
  },
  informationLabel: {
    id: 'MediaViewer.OpenMap',
    defaultMessage: 'Information',
  },
});

interface Props {
  projectMediaDetails: ProjectMediaDetails;
}

export const MediaViewer: React.FC<Props> = ({ projectMediaDetails }) => {
  const { formatMessage } = useIntl();
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);

  const mapUrl = GetMapsUrl(
    projectMediaDetails?.latitude,
    projectMediaDetails?.longitude,
    isMobile && isAndroid
  );

  const photo = (
    <SecureInnerImageZoom
      className='tw-h-full'
      src={projectMediaDetails?.url || ''}
      zoomSrc={projectMediaDetails?.downloadUrl}
    />
  );

  const video = (
    <SecureVideo src={projectMediaDetails.url} />
  );

  const mediaDetails = (
    <Grid
      container
      className='tw-pb-4 tw-p-4 tw-bg-white'
      direction='row'
      justify='space-between'>
      <Box>
        <Typography component='h2' variant='h6' className='tw-capitalize'>
          {moment.utc(projectMediaDetails?.uploadDate).local().format('LLLL')}
        </Typography>
        <Typography>{projectMediaDetails?.author?.name}</Typography>
        {projectMediaDetails?.createDate && (
          <Typography variant='body2'>
            {formatMessage(m.createdLabel)}{' '}
            {moment.utc(projectMediaDetails?.createDate).local().format('LLLL')}
          </Typography>
        )}
      </Box>
      {mapUrl !== '' && (
        <IconButton
          color='primary'
          aria-label={formatMessage(m.openMap)}
          component='a'
          className='tw-bg-white'
          size='small'
          href={mapUrl}
          target='_blank'>
          <MapIcon />
        </IconButton>
      )}
    </Grid>
  );

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpenDrawer(open);
  };

  return (
    <div className='tw-flex tw-justify-center tw-items-center tw-h-full tw-box-border'>
      <div
        className='tw-max-h-full tw-grid'
        style={{ gridTemplateColumns: 'auto', gridTemplateRows: 'minmax(0, 1fr) auto' }}>
        <div className='tw-justify-self-center'>
          {projectMediaDetails?.mediaType === MediaType.Video ? video : photo}
        </div>
        <React.Fragment key="bottom">
          <Button color="primary" variant="outlined" className="tw-bg-white tw-rounded-none tw-capitalize" endIcon={<ExpandLessIcon />} onClick={toggleDrawer(!isOpenDrawer)}>{formatMessage(m.informationLabel)}</Button>
          <Drawer
            anchor="bottom"
            open={isOpenDrawer}
            onClose={toggleDrawer(false)}
            container={() => document.getElementById('root')}
          >
            {mediaDetails}
          </Drawer>
        </React.Fragment>
      </div>
    </div>
  );
};
