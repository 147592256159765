import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import { orderBy } from 'lodash';
import { useFetchActiveProjects } from 'modules/project/hooks/useFetchActiveProjects';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ProjectCard } from '../project-card';
import HistoryIcon from '@material-ui/icons/History';
import { Alert } from '@material-ui/lab';

const m = defineMessages({
  title: {
    id: 'ActiveProjects.Title',
    defaultMessage: 'Actif',
  },
  noProjects: {
    id: 'ActiveProjects.NoProject',
    defaultMessage: "Aucun projet actif récent trouvé"
  }
});

export const ActiveProjects: React.FC = () => {  
  const { formatMessage } = useIntl();
  const {projectsWithLastActivity, isFetched} = useFetchActiveProjects(5);

  const projectsOrdered = orderBy(projectsWithLastActivity, (i => i.lastActivityDate), ['desc']);

  return (
    <Box>
      <Grid container direction="row" justify="space-between" alignItems="center" style={{minHeight:48}}>
        <Grid container item direction="row" alignItems="center" xs={6}>
          <HistoryIcon />
          <Typography component="h2" variant="h6" color="primary" className="tw-ml-2">{formatMessage(m.title)}</Typography>
        </Grid>
      </Grid>
      {isFetched ? (
        <>
          {projectsOrdered.length > 0 ? (
            <>
              {projectsOrdered.map((projectWithActivity, index) => {
                return ( 
                  <ProjectCard key={index} project={projectWithActivity.project} lastActivity={projectWithActivity.lastActivityDate}/>
                )
              })}
            </>
          ) : (
            <Alert severity="info">{formatMessage(m.noProjects)}</Alert>
          )}
        </>
      ): (
        <LinearProgress />
      )}
    </Box>   
  );
};
